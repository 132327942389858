import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import "./Solutions.css";
import { useSolutionsContext } from "./context/ContentSolutionsContext";
import { useContext, useState } from "react";
import { ActualLangContext } from "../../../contexts/ActualLangContext";
import chevronDown from "../../../assets/icons/chevron-down.png";
import chevronUp from "../../../assets/icons/chevron-up.png";
import { useTranslation } from "react-i18next";
import { menuServices } from "../../../config/home/textPage";

export const Solutions = () => <SoultionsContent />;

const SoultionsContent = () => {
  const { t } = useTranslation();

  const {
    isLoadingSolutions,
    solutions,
    selectedArea,
    selectedSolution,
    setSelectedArea,
    setSelectedSolution,
  } = useSolutionsContext();

  const { actualLang } = useContext(ActualLangContext);

  if (isLoadingSolutions) {
    return (
      <Container className="d-flex justify-content-center align-items-center solutionsSpinner">
        <Spinner />
      </Container>
    );
  }
  return (
    <Container className="solutions" id="solutionsBlock">
      <Row className="justify-content-center">
        <Col md={3} className="d-none d-md-block solutionsMenu">
          <div className="sticky">
            <p>
              {t("menuServices.title", {
                title: `${menuServices.title}`,
              })}
            </p>
            <div className="solutionsCards">
              {solutions.map((solution, idx) => (
                <Card
                  key={idx}
                  onClick={() => {
                    if (selectedArea !== solution.area) {
                      setSelectedSolution(solution.solutions[0].topTitle);
                      window.location.href = "#solutionsBlock";
                    }
                    setSelectedArea(solution.area);
                  }}
                >
                  <h5>{solution.textArea}</h5>
                  {selectedArea === solution.area && (
                    <div className="solutionsCardsList">
                      {solution.solutions.map((sol, idx) => (
                        <div
                          key={`${sol.topTitle}-${idx}`}
                          onClick={() => setSelectedSolution(sol.topTitle)}
                        >
                          <a
                            href={`#${sol.topTitle}`}
                            className={`${
                              selectedSolution === sol.topTitle
                                ? "selected"
                                : ""
                            }`}
                          >
                            {sol.topTitle}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                </Card>
              ))}
            </div>
          </div>
        </Col>
        {/* MOBILE MENU */}
        <MobileMenu />
        <Col xs={11} md={{ span: 8, offset: 1 }} className="solutionsContent">
          <h2>{solutions.find((sol) => sol.area === selectedArea).textArea}</h2>
          {solutions
            .find((sol) => sol.area === selectedArea)
            .solutions.map((sol, idx) => (
              <div key={idx} id={sol.topTitle} className="solution">
                <h4>{sol.topTitle}</h4>
                <h3>{sol.title}</h3>
                <p>{sol.bajada}</p>
                <Button
                  href={`/${actualLang === "es" ? "soluciones" : "solutions"}/${
                    sol.url
                  }`}
                >
                  {t("menuServices.button", {
                    button: `${menuServices.button}`,
                  })}
                </Button>
              </div>
            ))}
        </Col>
      </Row>
    </Container>
  );
};

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { solutions, selectedArea, setSelectedArea, setSelectedSolution } =
    useSolutionsContext();
  const { t } = useTranslation();

  return (
    <>
      <Col
        xs={11}
        className="d-md-none solutionsMenuMobile"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="menuTitle">
          <span>
            {t("menuServices.title", {
              title: `${menuServices.title}`,
            })}
          </span>
          <Image
            src={isOpen ? chevronUp : chevronDown}
            className="chevron"
            alt="chevron-down"
          />
        </div>
        {isOpen && (
          <div className="solutionsCards">
            {solutions.map((solution, idx) => (
              <div
                key={`${solution.area}-${idx}`}
                onClick={() => {
                  if (selectedArea !== solution.area) {
                    setSelectedSolution(solution.solutions[0].topTitle);
                  }
                  setSelectedArea(solution.area);
                }}
                className={`${
                  selectedArea === solution.area ? "selected" : ""
                } solutionOption`}
              >
                {solution.textArea}
              </div>
            ))}
          </div>
        )}
      </Col>
    </>
  );
};
