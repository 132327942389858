import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "../../css/homeUsa.css";
import { bannerText, trusted } from "../../config/home/textPage";
import { useTranslation } from "react-i18next";
import chubbLogo from "../../assets/logos/clients/Logo Chubb.png";
import WULogo from "../../assets/logos/clients/Logo WU.png";
import santanderLogo from "../../assets/logos/clients/Logo Santander.png";
import HSBCLogo from "../../assets/logos/clients/HSBC.png";
import ISO from "../../assets/iso.svg";

const MainBannerUsaDesktop = () => {
  const { t, i18n } = useTranslation();

  const animatedRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (animatedRef.current) {
        animatedRef.current.style.transform = `translateX(-${
          scrollPosition * 3
        }px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container fluid className="heroBannerBackground">
        <Image
          src={ISO}
          alt="ISO"
          className="iso"
          id="isoBackground"
          ref={animatedRef}
        />
        <Container id="mainbannerUsa" fluid>
          <Row className="justify-content-center">
            <Col xs={10} md={8} className="content">
              <h1>
                {t("mainbannerHome.title", {
                  title: `${bannerText.title}`,
                })}
              </h1>
              <h3 className="pb-2">
                {t("mainbannerHome.subtitle", {
                  subtitle: `${bannerText.subTitle}`,
                })}
              </h3>
              <Row className="buttons align-items-center justify-content-center">
                <Button
                  variant="secondary"
                  href={i18n.language === "en" ? "/solutions" : "/soluciones"}
                >
                  {t("buttonsAndAccesories.ourServices", {
                    ourServices: `${bannerText.ourServices}`,
                  })}
                </Button>
                <Button
                  variant="primary"
                  href={`/${
                    i18n.language === "en" ? "en-en" : "es-es"
                  }/contact`}
                >
                  {t("buttonsAndAccesories.consult", {
                    consult: `${bannerText.buttonConsult}`,
                  })}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="trusted">
              <h4>
                {t("trusted.whoTrustUs", {
                  whoTrustUs: `${trusted.whoTrustUs}`,
                })}
              </h4>
              <Row>
                <Image src={chubbLogo} alt="Chubb" />
                <Image src={WULogo} alt="Western Union" />
                <Image src={santanderLogo} alt="Santander" />
                <Image src={HSBCLogo} alt="HSBC" />
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MainBannerUsaDesktop;
