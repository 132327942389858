import "./styles.css";
import engagement from "./assets/engagement.png";
import { useTranslation } from "react-i18next";
import { engagementText } from "../../../config/solutions/textPage";
import { Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
export const Engagement = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <section className="engagement">
        <h2 className="top-title">
          {t("engagement.topTitle", {
            topTitle: `${engagementText.topTitle}`,
          })}
        </h2>
        <h3 className="engagement-subtitle">
          {t("engagement.title", {
            title: `${engagementText.title}`,
          })}
        </h3>

        <div className="engagement-content">
          <div className="engagement-section">
            <h4 className="section-title">
              <span>1.</span>{" "}
              {t("engagement.teamCapacity.title", {
                title: `${engagementText.teamCapacity.title}`,
              })}
            </h4>
            <div className="section-content">
              <ul>
                <li>
                  <ReactMarkdown>
                    {t("engagement.teamCapacity.microsoftArchitect", {
                      microsoftArchitect: `${engagementText.teamCapacity.microsoftArchitect}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.teamCapacity.developmentRoles", {
                      developmentRoles: `${engagementText.teamCapacity.developmentRoles}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.teamCapacity.powerApps", {
                      powerApps: `${engagementText.teamCapacity.powerApps}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.teamCapacity.uxUi", {
                      uxUi: `${engagementText.teamCapacity.uxUi}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.teamCapacity.automationQA", {
                      automationQA: `${engagementText.teamCapacity.automationQA}`,
                    })}
                  </ReactMarkdown>
                </li>
              </ul>
              <ul>
                <li>
                  <ReactMarkdown>
                    {t("engagement.teamCapacity.scientificRoles", {
                      scientificRoles: `${engagementText.teamCapacity.scientificRoles}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.teamCapacity.productOwner", {
                      productOwner: `${engagementText.teamCapacity.productOwner}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.teamCapacity.agileCoach", {
                      agileCoach: `${engagementText.teamCapacity.agileCoach}`,
                    })}
                  </ReactMarkdown>
                </li>
              </ul>
            </div>
          </div>

          <div className="engagement-section">
            <h4 className="section-title">
              <span>2.</span>{" "}
              {t("engagement.agileTeams.title", {
                title: `${engagementText.agileTeams.title}`,
              })}
            </h4>
            <div className="section-content">
              <ul>
                <li>
                  <ReactMarkdown>
                    {t("engagement.agileTeams.nearshore", {
                      nearshore: `${engagementText.agileTeams.nearshore}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.agileTeams.hybrid", {
                      hybrid: `${engagementText.agileTeams.hybrid}`,
                    })}
                  </ReactMarkdown>
                </li>
              </ul>
              <ul>
                <li>
                  <ReactMarkdown>
                    {t("engagement.agileTeams.purePossumus", {
                      purePossumus: `${engagementText.agileTeams.purePossumus}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.agileTeams.productDevelopment", {
                      productDevelopment: `${engagementText.agileTeams.productDevelopment}`,
                    })}
                  </ReactMarkdown>
                </li>
              </ul>
            </div>
          </div>

          <div className="sprint-diagram">
            <img src={engagement} alt="Sprint / Iterations" />
          </div>

          <div className="engagement-section">
            <h4 className="section-title">
              <span>3.</span>{" "}
              {t("engagement.projectBasedWork.title", {
                title: `${engagementText.projectBasedWork.title}`,
              })}
            </h4>
            <div className="section-content">
              <ul>
                <li>
                  <ReactMarkdown>
                    {t("engagement.projectBasedWork.roadmap", {
                      roadmap: `${engagementText.projectBasedWork.roadmap}`,
                    })}
                  </ReactMarkdown>
                </li>
              </ul>
              <ul>
                <li>
                  <ReactMarkdown>
                    {t("engagement.projectBasedWork.deliverables", {
                      deliverables: `${engagementText.projectBasedWork.deliverables}`,
                    })}
                  </ReactMarkdown>
                </li>
              </ul>
            </div>
          </div>

          <div className="engagement-section">
            <h4 className="section-title">
              <span>4.</span>{" "}
              {t("engagement.managedServices.title", {
                title: `${engagementText.managedServices.title}`,
              })}
            </h4>
            <div className="section-content">
              <ul>
                <li>
                  <ReactMarkdown>
                    {t("engagement.managedServices.support", {
                      support: `${engagementText.managedServices.support}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.managedServices.processes", {
                      processes: `${engagementText.managedServices.processes}`,
                    })}
                  </ReactMarkdown>
                </li>
              </ul>
              <ul>
                <li>
                  <ReactMarkdown>
                    {t("engagement.managedServices.models", {
                      models: `${engagementText.managedServices.models}`,
                    })}
                  </ReactMarkdown>
                </li>
                <li>
                  <ReactMarkdown>
                    {t("engagement.managedServices.contracts", {
                      contracts: `${engagementText.managedServices.contracts}`,
                    })}
                  </ReactMarkdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};
