import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Dropdown,
  Image,
  Button,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "./loader";
import chevronDownIcon from "../../assets/icons/chevron-down.png";
import chevronUpIcon from "../../assets/icons/chevron-up.png";
import { useTranslation } from "react-i18next";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import { MegaMenu } from "./MegaMenu";

export const MenuUsaDesk = ({
  logo,
  options,
  buttons,
  handleChange,
  checked,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [prevHovering, setPrevHovering] = useState(null);
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const history = useHistory();
  const { i18n } = useTranslation();

  const { actualLang, setLang } = useContext(ActualLangContext);

  useEffect(() => {
    window.onscroll = function () {
      let y = window.scrollY;
      setScrollYPosition(y);
    };
  }, []);

  const isInSolutionsPage =
    window.location.pathname.includes("solutions/") ||
    window.location.pathname.includes("soluciones/");

  return (
    <div className="d-none d-sm-none d-md-none d-lg-block">
      <Navbar
        expand="lg"
        className={`navbar p-3 transition
        ${
          scrollYPosition > 600 || isHovering
            ? "fixed-top bg-white navbar-light"
            : "bg-transparent position-absolute w-100 shadow-none"
        }
        `}
        onMouseLeave={() => {
          setPrevHovering(isHovering);
          setIsHovering(false);
          setTimeout(() => setPrevHovering(null), 400);
        }}
      >
        <Container fluid className="p-0">
          <Navbar.Brand href={`/`}>
            {logo?.data ? (
              <img
                src={logo.data.attributes.url}
                alt={logo?.data.attributes.alternativeText}
                className="LogoHeader"
              ></img>
            ) : (
              <Loader />
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav
              className=" justify-content-end align-items-center"
              activeKey={window.location.pathname}
            >
              {options?.map((option) => (
                <>
                  {option.multipleOptions.data.length ? (
                    <Dropdown
                      className={
                        isHovering === option.id
                          ? "custom-dropdown show"
                          : "custom-dropdown"
                      }
                      onMouseEnter={() => setIsHovering(option.id)}
                    >
                      <Dropdown.Toggle
                        aria-expanded={isHovering === option.id ? true : false}
                        onClick={() => null}
                      >
                        {option.title}
                        <Image
                          src={chevronDownIcon}
                          alt="arrow icon"
                          className="inactive"
                        />
                        <Image
                          src={chevronUpIcon}
                          alt="arrow icon"
                          className="active"
                        />
                      </Dropdown.Toggle>
                      <div
                        className={
                          isHovering === option.id
                            ? "dropdown-menu show"
                            : prevHovering === option.id
                            ? "dropdown-menu hidden"
                            : "dropdown-menu"
                        }
                      >
                        <MegaMenu
                          items={option.multipleOptions.data}
                          key={option.id}
                        />
                      </div>
                    </Dropdown>
                  ) : (
                    <Nav.Link
                      href={
                        option.href.includes("https")
                          ? option.href
                          : `/${actualLang === "en" ? "en-en" : "es-es"}${
                              option.href
                            }`
                      }
                    >
                      {option.title}
                    </Nav.Link>
                  )}
                </>
              ))}
              {buttons?.map((button) => (
                <Button variant={button.variant} href={button.href}>
                  {button.textButton}
                </Button>
              ))}
            </Nav>
            {!isInSolutionsPage && (
              <div id="mySwitch">
                <input
                  type="checkbox"
                  id="switch"
                  checked={checked}
                  onChange={(event) => {
                    handleChange(event);
                    if (!checked) {
                      setLang("es");
                      i18n.changeLanguage("es");
                      localStorage.setItem("lang", "es");
                      const newUrl = window.location.pathname.replace(
                        `/en-en`,
                        `/es-es`
                      );
                      history.push(newUrl);
                    } else {
                      setLang("en");
                      i18n.changeLanguage("en");
                      localStorage.setItem("lang", "en");
                      const newUrl = window.location.pathname.replace(
                        `/es-es`,
                        `/en-en`
                      );
                      history.push(newUrl);
                    }
                  }}
                />
                <div className="app">
                  <div className="container-switch">
                    <label
                      id="switchLanguage"
                      className={scrollYPosition < 600 ? "bg-white" : ""}
                      for="switch"
                    >
                      <div className="toggle"></div>
                      <div className="language">
                        <p className="english">EN</p>
                        <p className="espanol">ES</p>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
