export const homeEs = {
  mainbannerHome: {
    title:
      "Conectamos ideas con innovación para transformar negocios en experiencias excepcionales",
    subtitle:
      "Nos enfocamos en brindar soluciones poderosas de ingeniería de software a medida",
  },
  trusted: {
    whoTrustUs: "Confían en nosotros",
  },
  menuServices: {
    title: "Soluciones",
    subtitle1: "Potencie su negocio con nuestras soluciones",
    subtitle2: "nuestras soluciones",
    digitalProductSubtitle:
      "Impulsa tus ideas digitales con soluciones personalizadas que transforman negocio",
    digitalProductPart1: "Ingeniería de ",
    digitalProductPart2: "productos digitales",
    cloudAndDevOpsSubtitle:
      "Optimiza tu infraestructura con soluciones cloud seguras y gestión confiable de TI",
    cloudAndDevOpsPart1: "Servicios Cloud ",
    cloudAndDevOpsPart2: "y DevOps",
    dataScienceSubtitle:
      "Convierte datos en decisiones estratégicas con nuestro análisis avanzado",
    dataSciencePart1: "Ciencia de ",
    dataSciencePart2: "datos",
    aiEngineeringSubtitle:
      "Potencia tu negocio con estrategias de AI y soluciones personalizadas de Machine Learning",
    aiEngineeringPart1: "AI/ML Engineering ",
    aiEngineeringPart2: "& AI Strategy",
    button: "Saber más",
    sre: "Cloud Engineering & DevOps Practice",
    staffPart1: "Staff",
    staffPart2: "Augmentation",
    staffPart1MenuHeader: "Staff augmentation",
    buttonSend: "Enviar",
  },
  whatWeDo: {
    topTitle: "Soluciones",
    title1: "Ingeniería de productos digitales",
    description1:
      "Transformamos tus ideas en experiencias digitales excepcionales con soluciones tecnológicas adaptadas a tus objetivos",
  },
  bannerDownloadFree: {
    title: "Descargue gratis nuestro brochure",
  },
  page404: {
    title: "Algo ha desaparecido...",
    paragrahp:
      "Lo sentimos, el contenido que busca no existe. O bien ha sido eliminado, o ha escrito mal el enlace.",
    button: "Ir al Home",
  },
};

export const homeEn = {
  mainbannerHome: {
    title: "{{title}}",
    subtitle: "{{subtitle}}",
    services: "{{servicesHome}}",
  },
  trusted: {
    whoTrustUs: "{{whoTrustUs}}",
  },
  menuServices: {
    title: "{{title}}",
    subtitle1: "{{subtitle1}}",
    subtitle2: "{{subtitle2}}",
    softPart1: "{{softPart1}}",
    softPart2: "{{softPart2}}",
    button: "{{button}}",
    sre: "{{sre}}",
    staffPart1: "{{staffPart1}}",
    staffPart2: "{{staffPart2}}",
    staffPart1MenuHeader: "{{staffPart1MenuHeader}}",
    digitalProductSubtitle: "{{digitalProductSubtitle}}",
    digitalProductPart1: "{{digitalProductPart1}}",
    digitalProductPart2: "{{digitalProductPart2}}",
    cloudAndDevOpsSubtitle: "{{cloudAndDevOpsSubtitle}}",
    cloudAndDevOpsPart1: "{{cloudAndDevOpsPart1}}",
    cloudAndDevOpsPart2: "{{cloudAndDevOpsPart2}}",
    dataScienceSubtitle: "{{dataScienceSubtitle}}",
    dataSciencePart1: "{{dataSciencePart1}}",
    dataSciencePart2: "{{dataSciencePart2}}",
    aiEngineeringSubtitle: "{{aiEngineeringSubtitle}}",
    aiEngineeringPart1: "{{aiEngineeringPart1}}",
    aiEngineeringPart2: "{{aiEngineeringPart2}}",
    buttonSend: "{{buttonSend}}",
  },
  bannerDownloadFree: {
    title: "{{bannerDownloadFree}}",
  },
  page404: {
    title: "{{title}}",
    paragrahp: "{{paragrahp}}",
    button: "{{button}}",
  },
};
