import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import Linkedin from "../../assets/linkedin.svg";
import Twitter from "../../assets/twitter.svg";
import "../../css/footerUsa.css";
import { ActualLangContext } from "../../contexts/ActualLangContext";

const FooterUsaMobile = ({ logoPossumus, logoIso, options }) => {
  let newDate = new Date();
  let year = newDate.getFullYear();
  const { actualLang } = useContext(ActualLangContext);
  return (
    <Container fluid id="footer">
      <Container className="containerFooterMobile">
        <Row className="justify-content-center">
          <Col xs={7}>
            <a href="/">
              <img
                src={logoPossumus}
                alt="Possumus"
                className="img-fluid  logoPossumus"
              ></img>
            </a>
          </Col>
          <Col xs={7}>
            <div
              className="clutch-widget justify-content-center d-flex align-items-center"
              data-url="https://widget.clutch.co"
              data-widget-type="2"
              data-height="45"
              data-nofollow="true"
              data-expandifr="true"
              data-primary-color="#fcd702"
              data-secondary-color="#fcd702"
              data-clutchcompany-id="1572791"
            ></div>
          </Col>
          <Col xs={7} className="col-iso">
            <img
              src={logoIso}
              alt="Possumus"
              className="img-fluid  logoIso"
            ></img>
          </Col>
          {options?.map((option) => (
            <Col sm={2} className="col-menu-footer">
              <p className="titleFooter">{option.title}</p>
              {option.multipleOptions.data?.length > 0 &&
                option.multipleOptions.data.map((multipleOption) => (
                  <p>
                    <a
                      href={
                        multipleOption.attributes.href.includes("https") ||
                        multipleOption.attributes.href.includes("#") ||
                        multipleOption.attributes.href === "/solutions" ||
                        multipleOption.attributes.href === "/soluciones"
                          ? multipleOption.attributes.href
                          : `/${actualLang === "en" ? "en-en" : "es-es"}${
                              multipleOption.attributes.href
                            }`
                      }
                    >
                      {multipleOption.attributes.title}
                    </a>
                  </p>
                ))}
            </Col>
          ))}
        </Row>
        <Row className="disclaimer">
          <Col xs="12">
            <p>Copyright © {year} Possumus. All Rigths Reserved.</p>
          </Col>
          <Col xs="12" className="redes d-flex justify-content-center p-0">
            <ul>
              <li>
                <p className="d-block">Join Us</p>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/possumus.tech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Instagram}
                    alt="Instagram"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/possumus.tech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Facebook}
                    alt="Facebook"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/possumustech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Linkedin}
                    alt="Linkedin"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/PossumusTech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Twitter" className="img-fluid"></img>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default FooterUsaMobile;
