/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom/cjs/react-router-dom";
import MenuUsa from "../components/commons/menuUsa";
import { Footer } from "../components/commons/footer";
import {
  Banner,
  Cards,
  Form,
  Separator,
} from "../components/VerticalGenericPage";
import { Container, Spinner } from "react-bootstrap";
import { DownloadBanner } from "../components/commons/downloadBanner";
import { LocationAddress } from "../components/usa/locationAddress";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ActualLangContext } from "../contexts/ActualLangContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import sebastian from "../assets/landings/houston/team/SebastianDelfino.svg";
import federico from "../assets/landings/houston/team/FedericoTagliani.png";
import fernando from "../assets/landings/houston/team/FernandoBulczynski.png";
import marisa from "../assets/landings/houston/team/MarisaVaira.svg";
import GetInTouchMultiple from "../components/commons/getInTouchMultiple";

export const VerticalGenericPage = () => {
  const { lang, slug } = useParams();
  const [isLoadingSolution, setIsLoadingSolution] = useState(false);
  const [solution, setSolution] = useState({});
  const { setLang } = useContext(ActualLangContext);

  const isEnglish = lang === "solutions";

  useEffect(() => {
    const fetchSolution = async () => {
      try {
        setIsLoadingSolution(true);
        const response = await axios.get(
          `${process.env.REACT_APP_URL_API_V4}/api/solutions?locale=${
            lang === "solutions" ? "en" : "es"
          }&filters[banner][url]=${slug}&populate=deep`,
          {
            headers: {
              Authorization: `bearer ${process.env.REACT_APP_URL_API_V4_TOKEN}`,
            },
          }
        );

        if (!response.data?.data?.length) {
          console.warn("No se encontraron soluciones en la API.");
          return;
        }

        setSolution(response.data.data[0].attributes);
        setIsLoadingSolution(false);
      } catch (error) {
        console.error("Error al obtener soluciones:", error);
      }
    };

    fetchSolution();
  }, []);

  const personalES = [
    {
      nombre_apellido: "Fernando Bulczynski",
      puesto: "Chief Growth Officer",
      Image_personal: { url: fernando },
    },
    {
      nombre_apellido: "Marisa Vaira",
      puesto: "Sales Consultant",
      Image_personal: { url: marisa },
    },
  ];
  const personalEN = [
    {
      nombre_apellido: "Federico Tagliani",
      puesto: "Managing Director",
      Image_personal: {
        url: federico,
      },
    },
    {
      nombre_apellido: "Sebastian Delfino",
      puesto: "Business Developer",
      Image_personal: {
        url: sebastian,
      },
    },
  ];

  useEffect(() => {
    if (isEnglish) {
      setLang("en");
    } else {
      setLang("es");
    }
  }, [lang]);

  if (isLoadingSolution) {
    return (
      <Container className="d-flex justify-content-center align-items-center solutionsSpinner">
        <Spinner />
      </Container>
    );
  } else {
    const metadata = {
      title: `Possumus | ${solution.banner?.topTitle || ""}`,
      description: solution.banner?.description || "",
    };
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>{metadata.title}</title>
            <meta property="og:title" content={metadata.title} />
            <meta property="og:description" content={metadata.description} />
          </Helmet>
        </HelmetProvider>
        <MenuUsa />
        <Container className="position-relative m-0 p-0" fluid>
          <Banner {...solution.banner} />
          <Form origin={slug} />
          <Cards data={solution.cardsSection} />
        </Container>
        <Separator {...solution.separator} />
        <DownloadBanner />
        <GetInTouchMultiple personal={isEnglish ? personalEN : personalES} />
        <LocationAddress />
        <Footer />
      </>
    );
  }
};
