import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import DedicatedTeams from "../pages/DedicatedTeams";
import DevOpsSre from "../pages/DevOpsSre";
import SoftwareServices from "../pages/SoftwareServices";
import About from "../pages/About";
import Careers from "../pages/Careers";
import ContactPage from "../pages/Contact";
import Helpdesk from "../pages/Helpdesk";
import ReactGA from "react-ga";
import OrganizationalPolicy from "../pages/OrganizationalPolicy";
import Page404 from "../pages/404";
import VivaGoals from "../landings/vivaGoals";
import Bdd from "../landings/bdd";
import Productividad2023 from "../landings/productividad3652023";
import ProductDiscovery from "../landings/productDiscovery";
import ITAsAService from "../landings/iTAsAService";
import { Sre } from "../landings/sre";
import { HomeUsa } from "../pages/HomeUsa";
import StaffAugmentation from "../pages/StaffAugmentation";
import Mse from "../landings/mse";
import { Culture } from "../landings/culture";
import MicrosoftEcosystem from "../pages/MicrosoftEcosystem";
import Products from "../pages/Products";
import Itaas from "../pages/Itaas";
import SimplePay from "../landings/SimplePay";
import NearshoreUS from "../landings/houston/NearshoreUS";
import NearshoreES from "../landings/houston/NearshoreES";
import LandingAwards from "../landings/awards";
import { ContentAwardsLandingProvider } from "../contexts/ContentAwardsLandingContext";
import DevOpsSreLanding from "../landings/comercial/DevOpsSre";
import SoftwareEngineeringLanding from "../landings/comercial/SoftwareEngineering";
import StaffAugmentationLanding from "../landings/comercial/StaffAugmentation";
import MicrosoftEcosystemLanding from "../landings/comercial/MicrosoftEcosystem";
import ITaaSLanding from "../landings/comercial/ITaaS";
import AISoftwareSolutions from "../landings/comercial/AISoftwareSolutions";
import { ContentMicrosoftEcosystemProvider } from "../contexts/ContentMicrosoftEcosystemContext";
import { ContentOrganizationalPoliciesProvider } from "../contexts/ContentOrganizationalPoliciesContext";
import { ContentProductsProvider } from "../contexts/ContentProductsContext";
import { ContentItaasProvider } from "../contexts/ContentItaasContext";
import { ContentSimplePayLandingProvider } from "../contexts/ContentSimplePayLandingContext";
import { ContentSEProvider } from "../contexts/ContentSEContext";
import { ContentStaffAugmentationProvider } from "../contexts/ContentStaffAugmentationContext";
import { ContentDevOpsProvider } from "../contexts/ContentDevOpsContext";
import { ContentHomeProvider } from "../contexts/ContentHomeContext";
import { VerticalGenericPage } from "../pages/VerticalGenericPage";
import {  SolutionsPage } from "../pages/Solutions";

export const AppRouter = () => {
  useEffect(() => {
    ReactGA.initialize("UA-90867938-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`/`}
          component={() => (
            <ContentHomeProvider>
              <HomeUsa />
            </ContentHomeProvider>
          )}
        />
        <Route
          exact
          path={`/:lang(en-en|es-es)/dedicate-teams-service`}
          component={DedicatedTeams}
        />
        <Route
          path={`/:lang(en-en|es-es)/devops-sre/:section?`}
          component={() => (
            <ContentDevOpsProvider>
              <DevOpsSre />
            </ContentDevOpsProvider>
          )}
        />
        <Route
          path={`/:lang(en-en|es-es)/software-engineering/:section?`}
          component={() => (
            <ContentSEProvider>
              <SoftwareServices />
            </ContentSEProvider>
          )}
        />
        <Route
          path={`/:lang(en-en|es-es)/staff-augmentation/:section?`}
          component={() => (
            <ContentStaffAugmentationProvider>
              <StaffAugmentation />
            </ContentStaffAugmentationProvider>
          )}
        />
        <Route
          path={`/:lang(en-en|es-es)/microsoft-ecosystem/:section?`}
          component={() => (
            <ContentMicrosoftEcosystemProvider>
              <MicrosoftEcosystem />
            </ContentMicrosoftEcosystemProvider>
          )}
        />
        <Route
          path={`/:lang(en-en|es-es)/itaas/:section?`}
          component={() => (
            <ContentItaasProvider>
              <Itaas />
            </ContentItaasProvider>
          )}
        />
        <Route exact path={`/:lang(en-en|es-es)/about`} component={About} />
        <Route exact path={`/:lang(en-en|es-es)/careers`} component={Careers} />
        <Route
          exact
          path={`/:lang(en-en|es-es)/contact`}
          component={ContactPage}
        />
        <Route exact path={`/helpdesk`} component={Helpdesk} />

        <Route
          exact
          path={`/:lang(en-en|es-es)/organizational-policy`}
          component={() => (
            <ContentOrganizationalPoliciesProvider>
              <OrganizationalPolicy />
            </ContentOrganizationalPoliciesProvider>
          )}
        />
        <Route
          exact
          path={`/:lang(en-en|es-es)/:products(products|productos)`}
          component={() => (
            <ContentProductsProvider>
              <Products />
            </ContentProductsProvider>
          )}
        />
        {/* Misma ruta con redireccion a sección: */}
        <Route
          exact
          path={`/:lang(en-en|es-es)/:products(products|productos)/:section`}
          component={() => (
            <ContentProductsProvider>
              <Products />
            </ContentProductsProvider>
          )}
        />
        {/**Ruteo de landings */}
        <Route
          exact
          path={`/:lang(en-en|es-es)/:awards(awards|reconocimientos)`}
          component={() => (
            <ContentAwardsLandingProvider>
              <LandingAwards />
            </ContentAwardsLandingProvider>
          )}
        />
        {/* LANDINGS COMERCIAL */}
        <Route
          exact
          path={`/:lang(services|servicios)/devops-sre`}
          component={DevOpsSreLanding}
        />
        <Route
          exact
          path={`/:lang(services|servicios)/software-engineering`}
          component={SoftwareEngineeringLanding}
        />
        <Route
          exact
          path={`/:lang(services|servicios)/staff-augmentation`}
          component={StaffAugmentationLanding}
        />
        <Route
          exact
          path={`/:lang(services|servicios)/microsoft-ecosystem`}
          component={() => (
            <ContentMicrosoftEcosystemProvider>
              <MicrosoftEcosystemLanding />
            </ContentMicrosoftEcosystemProvider>
          )}
        />
        <Route
          exact
          path={`/:lang(services|servicios)/ITaaS`}
          component={() => (
            <ContentItaasProvider>
              <ITaaSLanding />
            </ContentItaasProvider>
          )}
        />

        {/* NUEVAS VERTICALES */}
        <Route
          exact
          path="/:lang(solutions|soluciones)"
          component={SolutionsPage}
        />
        <Route
          exact
          path="/:lang(solutions|soluciones)/:slug"
          component={VerticalGenericPage}
        />

        <Route
          exact
          path="/ai-software-solutions"
          component={AISoftwareSolutions}
        />
        <Route exact path="/nearshore-latam-us" component={NearshoreUS} />
        <Route exact path="/nearshore-latam-es" component={NearshoreES} />
        <Route exact path="/houston/:name?/:surname?">
          <Redirect to="/nearshore-latam-us" />
        </Route>
        <Route
          exact
          path="/:lang(en-en|es-es)/simple-pay"
          component={() => (
            <ContentSimplePayLandingProvider>
              <SimplePay />
            </ContentSimplePayLandingProvider>
          )}
        />
        <Route
          exact
          path="/webinars/it-as-a-service"
          component={ITAsAService}
        />
        <Route
          exact
          path="/webinars/product-discovery"
          component={ProductDiscovery}
        />
        <Route exact path="/webinars/ms-dynamics-sales" component={Mse} />
        <Route
          exact
          path="/webinars/microsoft-dynamics-sales"
          component={Mse}
        />
        <Route exact path="/:lang(en-en|es-es)/culture" component={Culture} />
        <Route exact path="/webinars/viva-goals" component={VivaGoals} />
        <Route exact path="/webinars/bdd" component={Bdd} />
        <Route exact path="/webinars/sre" component={Sre} />
        <Route
          exact
          path="/webinars/productividad-microsoft-365"
          component={Productividad2023}
        />
        <Route exact path="*" component={Page404} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
