import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { homeEs, homeEn } from "../config/home/home";
import {
  buttonsAndAccesoriesEn,
  buttonsAndAccesoriesEs,
} from "./Commons/buttonsAndAccesories";
import { aboutUsEn, aboutUsEs } from "../config/about/aboutUs";
import {
  valueProposalEn,
  valueProposalEs,
} from "./valueProposal/valueProposal";
import {
  softwareEngineeringEn,
  softwareEngineeringEs,
} from "./softwareEngineering/softwareEngineering";
import {
  organizationalPolicyEn,
  organizationalPolicyEs,
} from "./organizationalPolicy/organizationalPolicy";
import { careersEn, careersEs } from "./careers/careers";
import { contactEn, contactEs } from "./contact/contact";

import { sreEn, sreEs } from "./sre/sre";
import { ourPartnersEn, ourPartnersEs } from "./ourPartners/ourPartners";
import { getInTouchEn, getInTouchEs } from "./getInTouch/getInTouch";
import { staffEn, staffEs } from "./StaffAugmentation/staff";
import { cultureEn, cultureEs } from "./culture/culture";
import {
  VerticalGenericPageEN,
  VerticalGenericPageES,
} from "./VerticalGenericPage/VerticalGenericPage";
import { solutionsEn, solutionsEs } from "./solutions/solutions";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        translation: {
          ...homeEs,
          ...buttonsAndAccesoriesEs,
          ...aboutUsEs,
          ...valueProposalEs,
          ...softwareEngineeringEs,
          ...organizationalPolicyEs,
          ...careersEs,
          ...contactEs,
          ...staffEs,
          ...sreEs,
          ...ourPartnersEs,
          ...getInTouchEs,
          ...cultureEs,
          ...VerticalGenericPageES,
          ...solutionsEs,
        },
      },
      en: {
        translation: {
          ...homeEn,
          ...buttonsAndAccesoriesEn,
          ...aboutUsEn,
          ...valueProposalEn,
          ...softwareEngineeringEn,
          ...organizationalPolicyEn,
          ...careersEn,
          ...contactEn,
          ...staffEn,
          ...sreEn,
          ...ourPartnersEn,
          ...getInTouchEn,
          ...cultureEn,
          ...VerticalGenericPageEN,
          ...solutionsEn,
        },
      },
    },
  });
