export const form = {
  topTitle: "Let's cooperate",
  title: "Custom quote",
  description:
    "Accelerate innovation in your company with our technological solutions, fill out the form and we will contact you.",
  namePlaceholder: "Name*",
  emailPlaceholder: "Email*",
  messagePlaceholder: "Message",
  successTitle: "Thank you, your enquiry has been sent",
  successMessage: "We will contact you shortly",
  errorTitle: "Oops! We couldn't send the query",
  errorMessage: "Please try again later",
  emailError: "Please enter a valid email",
  nameError: "Please write your full name",
};
