import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "../../../css/homeUsa.css";
import { useTranslation } from "react-i18next";
import ISO from "../../../assets/iso.svg";
import { bannerText } from "../../../config/solutions/textPage";

export const Banner = () => {
  const { t } = useTranslation();

  const animatedRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (animatedRef.current) {
        animatedRef.current.style.transform = `translateX(-${
          scrollPosition * 3
        }px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container fluid className="heroBannerBackground">
        <Image
          src={ISO}
          alt="ISO"
          className="iso"
          id="isoBackground"
          ref={animatedRef}
        />
        <Container id="mainbannerUsa" fluid>
          <Row className="justify-content-center">
            <Col xs={10} md={5} className="content">
              <h1>
                {t("banner.title", {
                  title: `${bannerText.title}`,
                })}
              </h1>
              <h3 className="pb-2">
                {t("banner.subtitle", {
                  subtitle: `${bannerText.subTitle}`,
                })}
              </h3>
              <Row className="buttons align-items-center justify-content-center">
                <Button variant="primary" href="#getInTouch">
                  {t("buttonsAndAccesories.consult", {
                    consult: `${bannerText.buttonConsult}`,
                  })}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="trusted"></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
