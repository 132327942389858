import { Button, Col, Container, Row } from "react-bootstrap";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { contact } from "../../../config/home/textPage";

export const Separator = ({ title, description, slogan }) => {
  const { t } = useTranslation();
  return (
    <Container fluid className="verticalGenericPageSeparator">
      <Container>
        <Row>
          <Col md={{ span: 6 }}>
            <h3>{title}</h3>
            <p>{description}</p>
            <Button variant="secondary" href="#getInTouch">
              {t("contact.contactLink", {
                contactLink: `${contact.contactLink} `,
              })}
            </Button>
          </Col>
          <Col
            md={{ span: 4, offset: 1 }}
            className="d-flex align-items-center"
          >
            <p className="pill">{slogan}</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
