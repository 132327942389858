import { useContext, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import "../../css/MegaMenu.css";
import { ActualLangContext } from "../../contexts/ActualLangContext";

export const MegaMenu = ({ items }) => {
  const [selected, setSelected] = useState(items[0].id);
  const { actualLang } = useContext(ActualLangContext);

  const selectedLinks = items.filter((item) => item.id === selected)[0]
    .attributes.links;

  return (
    <Row className="megaMenu">
      <Col lg={{ offset: 1, span: 3 }} id="megaMenuItemContainer">
        {items.map((item, idx) => (
          <div
            key={idx}
            onMouseEnter={() => setSelected(item.id)}
            className={
              item.id === selected ? "megaMenuItem selected" : "megaMenuItem"
            }
          >
            <a
              href={
                item.attributes.href.includes("https") ||
                item.attributes.href.includes("#") ||
                item.attributes.href === "/solutions" ||
                item.attributes.href === "/soluciones"
                  ? item.attributes.href
                  : item.attributes.href
                  ? `/${actualLang === "en" ? "en-en" : "es-es"}${
                      item.attributes.href
                    }`
                  : ""
              }
            >
              <p>{item.attributes.title}</p>
              {item.attributes.slogan && (
                <p className="small-content">{item.attributes.slogan}</p>
              )}
            </a>
            {item.id === selected && (
              <div className="mobileLinks">
                {selectedLinks.map((link, index) => (
                  <h5 key={index}>
                    <a
                      href={
                        link.href.includes("https")
                          ? link.href
                          : items.filter((item) => item.id === selected)[0]
                              .attributes.href
                          ? `/${actualLang === "en" ? "en-en" : "es-es"}${
                              items.filter((item) => item.id === selected)[0]
                                .attributes.href
                            }/${link.href}`
                          : null
                      }
                    >
                      {link.title}
                    </a>
                  </h5>
                ))}
              </div>
            )}
          </div>
        ))}
      </Col>
      {items.map((item) => {
        return (
          <>
            {item.id === selected && (
              <Col
                key={item.id}
                lg={6}
                className="d-flex flex-column align-items-start"
                id="megaMenuItemDescription"
              >
                <Row>
                  <Col lg={7}>
                    <h4>
                      <a
                        href={
                          item.attributes.href.includes("https") ||
                          item.attributes.href.includes("#") ||
                          item.attributes.href === "/solutions" ||
                          item.attributes.href === "/soluciones"
                            ? item.attributes.href
                            : item.attributes.href
                            ? `/${actualLang === "en" ? "en-en" : "es-es"}${
                                items.filter((item) => item.id === selected)[0]
                                  .attributes.href
                              }`
                            : null
                        }
                      >
                        {item.attributes.title}
                      </a>
                    </h4>
                    <ReactMarkdown>{item.attributes.description}</ReactMarkdown>
                  </Col>
                  <Col lg={4}>
                    <Image
                      src={item.attributes.image?.data.attributes.url}
                      alt={
                        item.attributes.image?.data.attributes
                          ?.alternativeText || "product image"
                      }
                      className="w-100"
                    />
                  </Col>
                </Row>
                <SplitLinksColumns item={item} />
              </Col>
            )}
          </>
        );
      })}
    </Row>
  );
};

const SplitLinksColumns = ({ item }) => {
  const links = item.attributes.links;
  const half = Math.ceil(links.length / 2);
  const columns =
    links.length > 4 ? [links.slice(0, half), links.slice(half)] : [links];

  const { actualLang } = useContext(ActualLangContext);

  return (
    <Row className={`linksContainer ${links.length > 4 ? "columns" : ""}`}>
      {columns.map((column, colIndex) => (
        <Col key={colIndex} md={columns.length === 1 ? 12 : 6}>
          {column.map((link, index) => (
            <h5 key={index}>
              <a
                href={
                  link.href.includes("https")
                    ? link.href
                    : item.attributes.href
                    ? `/${actualLang === "en" ? "en-en" : "es-es"}${
                        item.attributes.href
                      }/${link.href}`
                    : null
                }
              >
                {link.title}
              </a>
            </h5>
          ))}
        </Col>
      ))}
    </Row>
  );
};
