import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./styles.css";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { menuServices } from "../../../config/home/textPage";
import { form } from "../../../config/VerticalGenericPage/textPage";
import WLT from "../../../assets/logos/WLTBy.svg";

export const Form = ({ origin }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    yourname: "",
    email: "",
    message: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formMessage, setFormMessage] = useState({
    title: "",
    message: "",
    active: false,
  });
  const [formErrors, setFormErrors] = useState({
    yourname: "",
    email: "",
  });
  const isEmail =
    /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;

  function handleInputChanges({ target }) {
    const { name, value } = target;
    setButtonDisabled(false);
    setFormErrors({});
    setValues({
      ...values,
      [name]: value,
    });
  }

  async function sendData(token) {
    if (token) {
      let errors = {};
      if (!values.email || !isEmail.test(values.email)) {
        errors.email = t("form.emailError", {
          emailError: `${form.emailError}`,
        });
      }
      if (!values.yourname) {
        errors.yourname = t("form.nameError", {
          nameError: `${form.nameError}`,
        });
      }
      if (!errors.yourname && !errors.email) {
        await axios
          .post(
            `https://prod-20.brazilsouth.logic.azure.com:443/workflows/4a0de618d958419bb7dc99ba7a3245b7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QsAL47KtZeSa5He1YXBvXMI4ZsD802JoaITVlC2wXhs`,
            {
              origin: `Possumus - ${origin}`,
              ...values,
              response: token,
            }
          )
          .then((res) => {
            if (res.data.success) {
              setFormMessage({
                title: t("form.successTitle", {
                  successTitle: `${form.successTitle}`,
                }),
                message: t("form.successMessage", {
                  successMessage: `${form.successMessage}`,
                }),
                active: true,
              });
            } else {
              throw new Error("Algo salió mal");
            }
          })
          .catch((error) => {
            console.log(error);
            setFormMessage({
              title: t("form.errorTitle", {
                errorTitle: `${form.errorTitle}`,
              }),
              message: t("form.errorMessage", {
                errorMessage: `${form.errorMessage}`,
              }),
              active: true,
            });
          })
          .finally(() => {
            setValues({ name: "", email: "", message: "" });
            setButtonDisabled(false);
          });
      } else {
        setFormErrors(errors);
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setButtonDisabled(true);
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdKISYdAAAAAAZ_z3WlZ1LGYiaM_70zPcZqaYfa", {
          action: "submit",
        })
        .then(function (token) {
          sendData(token);
        });
    });
  }
  return (
    <Container className="verticalGenericPageForm">
      <Row className="justify-content-end">
        <Col md={{ span: 5, offset: 1 }}> 
          <form
            className={
              formMessage.active
                ? "d-flex flex-column justify-content-center align-items-center"
                : ""
            }
          >
            {formMessage.active ? (
              <>
                <Image src={WLT} alt="We Love Tech" />
                <h5 className="text-center">{formMessage.title}</h5>
                <p className="text-center">{formMessage.message}</p>
              </>
            ) : (
              <>
                <span>
                  {t("form.topTitle", {
                    topTitle: `${form.topTitle}`,
                  })}
                </span>
                <h5>
                  {t("form.title", {
                    title: `${form.title}`,
                  })}
                </h5>
                <p>
                  {t("form.description", {
                    description: `${form.description}`,
                  })}
                </p>
                <div className="inputs">
                  <input
                    type="text"
                    placeholder={t("form.namePlaceholder", {
                      namePlaceholder: `${form.namePlaceholder}`,
                    })}
                    name="yourname"
                    className={formErrors.yourname ? "error" : ""}
                    onChange={handleInputChanges}
                  />
                  {formErrors.yourname && (
                    <p className="errorMessage">{formErrors.yourname}</p>
                  )}
                  <input
                    type="text"
                    placeholder={t("form.emailPlaceholder", {
                      emailPlaceholder: `${form.emailPlaceholder}`,
                    })}
                    className={formErrors.email ? "error" : ""}
                    name="email"
                    onChange={handleInputChanges}
                  />
                  {formErrors.email && (
                    <p className="errorMessage">{formErrors.email}</p>
                  )}
                  <textarea
                    placeholder={t("form.messagePlaceholder", {
                      messagePlaceholder: `${form.messagePlaceholder}`,
                    })}
                    name="message"
                    onChange={handleInputChanges}
                  />
                </div>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={buttonDisabled}
                >
                  {t("menuServices.buttonSend", {
                    buttonSend: `${menuServices.buttonSend}`,
                  })}
                </Button>
              </>
            )}
          </form>
        </Col>
        <Col md={1}></Col>
      </Row>
    </Container>
  );
};
