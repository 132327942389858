import { Col, Container, Image, Row } from "react-bootstrap";
import "./styles.css";
import bulletIcon from "../../../assets/icons/bulletIcon.svg";
import ReactMarkdown from "react-markdown";

export const Cards = ({ data }) => (
  <Container className="verticalGenericPageCardsContainer">
    <Container>
      <Row>
        <Col md={6} className="content z-index-10">
          {data?.map((card, index) => (
            <Row key={index}>
              <Col>
                <h4>{card.title}</h4>
                <ReactMarkdown className="cardSubtitle">
                  {card.subtitle}
                </ReactMarkdown>
                <div className="cardsContainer">
                  {card.cards.map((card, index) => (
                    <article key={index} className="verticalGenericPageCard">
                      <h5>{card.title}</h5>
                      <ReactMarkdown>{card.text}</ReactMarkdown>
                    </article>
                  ))}
                  {card.bullets?.length > 0 && (
                    <ul className="bulletsContainer">
                      {card.bullets.map((bullet, index) => (
                        <li key={index}>
                          <Image src={bulletIcon} alt="Bullet Icon" />
                          {bullet.bullet}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  </Container>
);
