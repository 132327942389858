import mariano from "../../assets/landings/houston/team/MarianoGurrieri.svg";
import sebastian from "../../assets/landings/houston/team/SebastianDelfino.svg";
import ilustrationITaaS from "../../assets/landings/houston/ilustrationITaaS.svg";
import ilustrationMSE from "../../assets/landings/houston/ilustrationMSE.svg";
import ilustrationEngineering from "../../assets/landings/houston/ilustrationEngineering.svg";
import softwareWLD from "../../assets/landings/houston/softwareWLD.svg";

export const SOFTWARE_ENGINEERING = {
  image: softwareWLD,
  bajada:
    "We develop digital products that create positive experiences for people. We iterate, achieving products that evolve, thus accelerating real responses to introduce and develop unique products in different markets.",
  descripcion1:
    "We can either create your product within our company or provide you with a complete team to speed up your processes and improve delivery times.",
  descripcion2:
    "Our performance is strong, providing a better balance between quality and economic efficiency.",
};

export const OUR_SERVICES = {
  title: "Our services",
  services: [
    {
      text: "Software Engineering",
      hover: true,
      href: "#houstonSoftwareEngineering",
    },
    {
      text: "Cloud Engineering & DevOps Practice",
      hover: true,
      href: "#devOpsPractice",
    },
    {
      text: "IT as a Service",
      hover: true,
      href: "#houstonItaas",
    },
    {
      text: "Microsoft Ecosystem",
      hover: true,
      href: "#houstonMse",
    },
  ],
  jumboButtonText: "Learn more",
};

export const SERVICES = [
  {
    id: "devOpsPractice",
    title: "Cloud Engineering & DevOps Practice",
    description:
      "We guarantee optimization and scalability for your systems with cloud-centric (o cloud centered) platform engineering, focusing strongly on security and performance optimization to maximize results and streamline (accelerate) processes.\n\n Our performance is strong, maintaining a balance between quality and economic efficiency.",
    image: ilustrationEngineering,
  },
  {
    id: "houstonItaas",
    title: "ITaaS (IT as a Service)",
    description:
      "We simplify digital transformation with scalable technology tailored to each project, promoting standardization, improving team availability, increasing productivity, reducing costs, directing investment, speeding up processes, and ensuring business continuity within your company.\n\n Our performance is strong, achieving optimal balance between quality and economic efficiency.",
    image: ilustrationITaaS,
  },
  {
    id: "houstonMse",
    title: "Microsoft Ecosystem",
    description:
      "We accelerate transformation and boost your business's productivity by connecting people, data, and processes with collaborative and automated tools that provide real-time measurement and enhance business decision-making.\n\n We maintain a strong focus on data security, efficient management, and user compliance.\n\n Our performance is strong, striking a better balance between quality and economic efficiency.",
    image: ilustrationMSE,
  },
];

export const TITLEANDBULLETSSECTION = [
  {
    id: "corporateSoftwareDevelopment",
    title: "Corporate Software Development",
    bullets: [
      {
        title: "Integrations",
        description:
          "Our solutions are the key to establishing a single source of truth, simplifying data management and exposure.",
      },
      {
        title: "Architecture",
        description:
          "We assist in migrating monolithic architectures to fully managed microservices.",
      },
      {
        title: "SSO",
        description:
          "Implement Single Sign On in your application ecosystem to address your Identity Management System needs and simplify the user experience, ensuring the right level of security.",
      },
      {
        title: "Middlewares",
        description:
          "Our middleware development services drive interoperability and scalability for your applications.",
      },
    ],
  },
  {
    id: "digitalProducts",
    title: "Digital Products",
    bullets: [
      {
        title: "Product Discovery",
        description:
          "Our methodology allows us to identify strategic opportunities, deeply understand market needs, and confidently lead innovation.",
      },
      {
        title: "Proof of Concept​",
        description:
          "We transform ideas into tangible solutions and validate their feasibility before investing significant resources.",
      },
      {
        title: "MVP​",
        description:
          "With our MVP development service, accelerate time-to-market by creating functional prototypes that validate your ideas and effectively manage your budget.",
      },
      {
        title: "Iterative Development",
        description:
          "We guarantee solutions that evolve with the changing market demands, where each improvement cycle brings us closer to your vision.​",
      },
    ],
  },
  {
    id: "uxServices",
    title: "UX Services",
    bullets: [
      {
        title: "User Research & Usability",
        description:
          "We focus on understanding users to design interfaces that meet their needs.",
      },
      {
        title: "Information Architecture",
        description:
          "We design information structures that enhance your app's navigation and comprehension.",
      },
      {
        title: "Content Strategy",
        description:
          "We optimize communication and content to effectively convey your brand's personality.",
      },
      {
        title: "Interaction Design",
        description:
          "We create dynamic interactions that engage users and elevate the overall experience.",
      },
      {
        title: "Visual Design",
        description:
          "We conceive a visual aesthetic that reflects your brand's identity and enhances your products' appearance.",
      },
    ],
  },
];

export const SIDENAVBAR_ITEMS = {
  id: 1,
  title: "Software Engineering",
  href: "houstonSoftwareEngineering",
  navItems: [
    { id: 1, Titulo: "Staff Augmentation", Href: "houstonStaff" },
    {
      id: 2,
      Titulo: "Corporate Software Development",
      Href: "corporateSoftwareDevelopment",
    },
    {
      id: 3,
      Titulo: "Digital Products",
      Href: "digitalProducts",
    },
    {
      id: 4,
      Titulo: "UX Services",
      Href: "uxServices",
    },
  ],
};

export const MEMBERS = [
  { name: "Mariano Gurrieri", charge: "CEO", image: mariano },
  {
    name: "Sebastian Delfino",
    charge: "Business Developer",
    image: sebastian,
  },
];

export const ourTeamPpalContent = {
  suptitle: "What moves us",
  title:
    "We drive digital innovation in companies to ensure their competitiveness, validity, and relevance in the market.",
  members: MEMBERS,
};

export const ourTeamContent = {
  suptitle: "OUR TEAM MEMBERS AT NEXGEN 2023",
  members: MEMBERS,
};

export const FOOTER_TEXT = {
  innovate: "Let’s innovate together",
  contact: {
    title: "Get in touch!",
    button: "Contact us",
    tel: "+1 608 423 6916",
  },
  redes: {
    title: "Join Us",
  },
};
