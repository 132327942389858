import MainBannerUsaDesktop from "./bannerUsaDesktop";

const MainBanner = () => {
  return (
    <>
      <MainBannerUsaDesktop />
    </>
  );
};

export default MainBanner;
