export const VerticalGenericPageES = {
  form: {
    topTitle: "Cooperemos",
    title: "Cotización a medida",
    description:
      "Acelera la innovación en tu empresa con nuestras soluciones tecnológicas, completa el formulario y nos comunicaremos con usted.",
    namePlaceholder: "Nombre*",
    emailPlaceholder: "Correo electrónico*",
    messagePlaceholder: "Mensaje",
    successTitle: "Gracias por contactarnos, su mensaje fue enviado",
    successMessage: "Nos pondremos en contacto a la brevedad",
    errorTitle: "Oops! No pudimos enviar su mensaje",
    errorMessage: "Por favor, inténtelo nuevamente más tarde",
    emailError: "Por favor, ingrese un email válido",
    nameError: "Por favor, ingrese su nombre",
  },
};

export const VerticalGenericPageEN = {
  form: {
    topTitle: "{{topTitle}}",
    title: "{{title}}",
    description: "{{description}}",
    namePlaceholder: "{{namePlaceholder}}",
    emailPlaceholder: "{{emailPlaceholder}}",
    messagePlaceholder: "{{messagePlaceholder}}",
    successTitle: "{{successTitle}}",
    successMessage: "{{successMessage}}",
    errorTitle: "{{errorTitle}}",
    errorMessage: "{{errorMessage}}",
    emailError: "{{emailError}}",
    nameError: "{{nameError}}",
  },
};
