import { createContext, useContext, useEffect, useState } from "react";
import { ActualLangContext } from "./ActualLangContext";
import axios from "axios";

export const ContentLayoutContext = createContext({});

export const ContentLayoutProvider = ({ children }) => {
  const [loadedData, setLoadedData] = useState(false);
  const [navbarContent, setNavbarContent] = useState();
  const [awardsContent, setAwardsContent] = useState();
  const [footerContent, setFooterContent] = useState();

  const { actualLang } = useContext(ActualLangContext);

  useEffect(() => {
    setLoadedData(false);
    axios
      .get(
        `${process.env.REACT_APP_URL_API_V4}/api/page-web-layout?locale=${actualLang}&populate=deep`,
        {
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_URL_API_V4_TOKEN}`,
          },
        }
      )
      .then((res) => {
        setNavbarContent(res.data.data.attributes.navbar);
        setAwardsContent(res.data.data.attributes.awardSection);
        setFooterContent(res.data.data.attributes.footer);
        setLoadedData(true);
      })
      .catch((err) => console.log(err));
  }, [actualLang]);
  return (
    <ContentLayoutContext.Provider
      value={{ loadedData, navbarContent, awardsContent, footerContent }}
    >
      {children}
    </ContentLayoutContext.Provider>
  );
};
