import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import Loader from "../commons/loader";
import "../../css/geitInTouch.css";
import { useTranslation } from "react-i18next";
import { getInTouch } from "../../config/getInTouch/textPage";
import { contact } from "../../config/home/textPage";
import { bannerDownload } from "../../config/Commons/textPage";
import { ActualLangContext } from "../../contexts/ActualLangContext";
export const LocationAddress = () => {
  const { t, i18n } = useTranslation();
  const [offices, setOffices] = useState([]);
  const [loadedData, setLoadedData] = useState(false);

  const { actualLang } = useContext(ActualLangContext);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/institucional`).then((res) => {
      setOffices(res.data.Oficinas);
      setLoadedData(true);
    });
  }, []);
  return (
    <Container fluid id="readyBuildUsa">
      <Container className="containerInfo">
        <Row className="justify-content-md-center text-center mb-4">
          <Col lg={12}>
            <h5 className="top-title text-uppercase">
              {t("getInTouchBanner.title2", {
                title2: `${getInTouch.title2} `,
              })}
            </h5>
          </Col>
        </Row>
        <div className="d-flex justify-content-center text-center">
          {window.location.pathname !==
          `/${i18n.language === "en" ? "en-en" : "es-es"}/contact` ? (
            <div className="d-flex justify-content-center" id="titleContainer">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4>
                  {t("getInTouchBanner.subtitle", {
                    subtitle: `${getInTouch.subtitle} `,
                  })}
                </h4>
                <Button
                  variant="primary"
                  className="btn-yellow"
                  href={`/${
                    i18n.language === "en" ? "en-en" : "es-es"
                  }/contact`}
                >
                  {t("contact.contactLink", {
                    contactLink: `${contact.contactLink} `,
                  })}
                </Button>
              </div>
            </div>
          ) : (
            <Col lg={12}>
              <h4 className="float-right">
                {t("getInTouchBanner.subtitle", {
                  subtitle: `${getInTouch.subtitle} `,
                })}
              </h4>
            </Col>
          )}
        </div>
        <Container className="addressUsa">
          {loadedData ? (
            offices.map((office) => (
              <Col md={2} key={office.id}>
                <h3>{office.Locacion}</h3>
                <p>{office.Direccion}</p>
                <p>{office.Localidad}</p>
                <p>{office.CP}</p>
                <p>{office.Provincia}</p>
                <p>{office.Pais}</p>
                <p>
                  <br></br>
                  <a href={"tel:" + office.Telefono}>
                    {t("buttonsAndAccesories.buttonCall", {
                      buttonCall: `${bannerDownload.buttonCall} `,
                    })}
                  </a>
                </p>
              </Col>
            ))
          ) : (
            <Loader />
          )}
        </Container>
        <Container className="d-flex justify-content-md-center letsTalk">
          <Row className="justify-content-md-center">
            <Col className="justify-content-center d-flex flex-column">
              <h3>{actualLang === "es" ? "Hablemos" : "Let's talk"}</h3>
              <p>
                <a href="tel: 08103450562">0810 345 0562</a>
              </p>
              <p>
                <a href="mailto:info@possumus.tech">info@possumus.tech</a>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};
