import React, { useContext, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "./styles.css";
import ISO from "../../../assets/iso.svg";
import { ActualLangContext } from "../../../contexts/ActualLangContext";
import { menuServices } from "../../../config/home/textPage";
import { useTranslation } from "react-i18next";

export const Banner = ({ title, description }) => {
  const { t } = useTranslation();
  const animatedRef = useRef(null);
  const { actualLang } = useContext(ActualLangContext);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (animatedRef.current) {
        animatedRef.current.style.transform = `translateX(-${
          scrollPosition * 3
        }px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container fluid className="heroBannerBackground">
        <Image
          src={ISO}
          alt="ISO"
          className="iso"
          id="isoBackground"
          ref={animatedRef}
        />
        <Container id="genericMainBanner" fluid>
          <Container>
            <Row>
              <Col md={6} className="content">
                <h1>{title}</h1>
                <h3 className="pb-2">{description}</h3>
                <Row className="buttons">
                  <Button
                    variant="primary"
                    href={`/${actualLang === "en" ? "en-en" : "es-es"}/contact`}
                  >
                    {t("menuServices.button", {
                      button: `${menuServices.button}`,
                    })}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Container>
          <Row className="justify-content-center">
            <Col className="blur"></Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
