import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { ActualLangContext } from "../../../../contexts/ActualLangContext";

const SolutionsContext = createContext({});

export const SolutionsProvider = ({ children }) => {
  const { actualLang } = useContext(ActualLangContext);
  const SOLUTIONS_AREAS = [
    "Ingeniería de productos digitales",
    "Servicios Cloud y DevOps",
    "Ingeniería de datos",
    "AI/ML Engineering & AI Strategy",
  ];
  const EN_SOLUTIONS_AREAS = [
    "Digital Product Engineering",
    "Managed IT, Cloud, and DevOps Services",
    "Data Engineering",
    "AI/ML Engineering & AI Strategy",
  ];

  const [isLoadingSolutions, setIsLoadingSolutions] = useState(true);
  const [solutions, setSolutions] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedSolution, setSelectedSolution] = useState("");

  useEffect(() => {
    const fetchSolutions = async () => {
      try {
        setIsLoadingSolutions(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_API_V4}/api/solutions?locale=${actualLang}&populate=deep`,
          {
            headers: {
              Authorization: `bearer ${process.env.REACT_APP_URL_API_V4_TOKEN}`,
            },
          }
        );

        if (!data?.data?.length) {
          console.warn("No se encontraron soluciones en la API.");
          return;
        }

        const mappedData = SOLUTIONS_AREAS.map((area, idx) => ({
          area,
          textArea: actualLang === "es" ? area : EN_SOLUTIONS_AREAS[idx],
          solutions: data.data
            .filter((sol) => sol?.attributes?.area === area)
            .map((sol) => ({
              topTitle: sol?.attributes?.banner?.topTitle || "Sin título",
              title: sol?.attributes?.banner?.title || "Sin descripción",
              bajada: sol?.attributes?.banner?.description || "Sin detalles",
              url: sol?.attributes?.banner?.url || "#",
            })),
        }));

        setSolutions(mappedData);
        setSelectedArea(mappedData[0]?.area || "");
        setSelectedSolution(mappedData[0]?.solutions[0]?.topTitle || "");
        setIsLoadingSolutions(false);
      } catch (error) {
        console.error("Error al obtener soluciones:", error);
      }
    };

    fetchSolutions();
  }, [actualLang]);

  return (
    <SolutionsContext.Provider
      value={{
        isLoadingSolutions,
        solutions,
        selectedArea,
        selectedSolution,
        setSelectedArea,
        setSelectedSolution,
        SOLUTIONS_AREAS,
      }}
    >
      {children}
    </SolutionsContext.Provider>
  );
};

export const useSolutionsContext = () => {
  return useContext(SolutionsContext);
};
