export const bannerDownload = {
  button: "Download",
  buttonCall: "Call us now",
  buttonGetFree: "Get Free Consultation",
  buttonGetInTouch: "Send now",
};
export const buttonsAndAccesories = {
  buttonContact: "Contact us",
  buttonGetInTouch: 'Send'
};
