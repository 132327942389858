import React, { useContext } from "react";
import {
  Container,
  Navbar,
  Nav,
  Row,
  Col,
  Dropdown,
  Button,
  Image,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "./loader";
import { useTranslation } from "react-i18next";
import chevronDownIcon from "../../assets/icons/chevron-down.png";
import chevronUpIcon from "../../assets/icons/chevron-up.png";
import { SeparatorMenu } from "./separatorMenu";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import { MegaMenu } from "./MegaMenu";

export const MenuUsaMobile = ({
  logo,
  options,
  buttons,
  handleChange,
  checked,
}) => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const { setLang } = useContext(ActualLangContext);

  const isInSolutionsPage =
    window.location.pathname.includes("solutions/") ||
    window.location.pathname.includes("soluciones/");

  return (
    <div className="d-block d-sm-block d-md-block d-lg-none">
      <Navbar
        expand="lg"
        className="navbar fixed-top navbar-light bg-white p-3"
      >
        <Container fluid className="p-0">
          <Navbar.Brand href={`/`}>
            {logo?.data ? (
              <img
                src={logo.data.attributes.url}
                alt={logo?.data.attributes.alternativeText}
                className="LogoHeader"
              ></img>
            ) : (
              <Loader />
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            aria-controls="basic-navbar-nav"
            id="mobileContainer"
            className="justify-content-end"
          >
            <Nav
              className=" justify-content-end containerNavItem mobile"
              activeKey={window.location.pathname}
            >
              {options?.map((option) => (
                <>
                  {option.multipleOptions.data.length ? (
                    <>
                      <Dropdown className="custom-dropdown">
                        <Dropdown.Toggle>
                          {option.title}
                          <Image
                            src={chevronDownIcon}
                            alt="arrow icon"
                            className="inactive"
                          />
                          <Image
                            src={chevronUpIcon}
                            alt="arrow icon"
                            className="active"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <MegaMenu items={option.multipleOptions.data} />
                        </Dropdown.Menu>
                      </Dropdown>
                      <SeparatorMenu />
                    </>
                  ) : (
                    <>
                      <Nav.Link href={option.href}>{option.title}</Nav.Link>
                      <SeparatorMenu />
                    </>
                  )}
                </>
              ))}

              <Row className="rowItem">
                <Col className="d-flex  align-items-center">
                  {buttons?.map((button) => (
                    <Button variant={button.variant} href={button.href}>
                      {button.textButton}
                    </Button>
                  ))}
                </Col>
                {!isInSolutionsPage && (
                  <Col className="d-flex justify-content-end align-items-center switchContainer">
                    <div id="mySwitch">
                      <input
                        type="checkbox"
                        id="switch"
                        checked={checked}
                        onChange={(event) => {
                          handleChange(event);
                          if (!checked) {
                            setLang("es");
                            i18n.changeLanguage("es");
                            localStorage.setItem("lang", "es");
                            const newUrl = window.location.pathname.replace(
                              `/en-en`,
                              `/es-es`
                            );
                            history.push(newUrl);
                          } else {
                            setLang("en");
                            i18n.changeLanguage("en");
                            localStorage.setItem("lang", "en");
                            const newUrl = window.location.pathname.replace(
                              `/es-es`,
                              `/en-en`
                            );
                            history.push(newUrl);
                          }
                        }}
                      />
                      <div className="app">
                        <div className="container-switch d-flex justify-content-center align-items-center">
                          <label id="switchLanguage" for="switch">
                            <div className="toggle"></div>
                            <div className="language">
                              <p className="english">EN</p>
                              <p className="espanol">ES</p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
