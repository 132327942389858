import { MSSeparator } from "../../commons/MSSeparator/MSSeparator";
import MSPartnerSolutions from "../../../assets/logos/MSSolutionPartner.svg";
import "./MSSeparator.css";
import { ActualLangContext } from "../../../contexts/ActualLangContext";
import { useContext } from "react";

const MSSeparatorItemsEN = [
  "Business Applications",
  "Data & AI",
  "Digital & App Innovation",
  "Infrastructure",
  "Security",
  "Modern Work",
];
const MSSeparatorItemsES = [
  "Business Applications",
  "Data & AI",
  "Innovación Digital y de Apps",
  "Infraestructura",
  "Seguridad",
  "Modern Work",
];

export const HomeMSSeparator = ({ items }) => {
  const { actualLang } = useContext(ActualLangContext);
  const isEnglish = actualLang === "en";
  if (!items) {
    items = isEnglish ? MSSeparatorItemsEN : MSSeparatorItemsES;
  }
  return (
    <MSSeparator className="homeMSSeparatorContainer">
      <div className="titleAndImageContainer">
        <MSSeparator.Title>
          {isEnglish
            ? "We are a Microsoft Solutions Partner"
            : "Somos Microsoft Solutions Partner"}
        </MSSeparator.Title>
      </div>
      <MSSeparator.ItemsList items={items} />
      <MSSeparator.Image
        src={MSPartnerSolutions}
        width={250}
        alt="MSSolutionPartner"
      />
    </MSSeparator>
  );
};
