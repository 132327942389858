import React, { useContext } from "react";
import { Button, Col, Container, Row, Image, Spinner } from "react-bootstrap";
import "../../css/downloadUsa.css";
import { useTranslation } from "react-i18next";
import useDownloadBrochure from "../../hooks/useDownloadBrochure";
import { ActualLangContext } from "../../contexts/ActualLangContext";

export const DownloadFreeUsa = ({
  imageBanner,
  textBanner,
  background,
  backgroundMobile,
  //Si se pasa una url por props utiliza esa, sino utiliza la del hook useDownloadBrochure
  brochureUrl,
}) => {
  const { t } = useTranslation();
  const { downloadUrl, isLoading } = useDownloadBrochure();

  const { actualLang } = useContext(ActualLangContext);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Container
        fluid
        className="d-none d-sm-none d-md-block containerBannerDownload containerBannerDownloadMobile"
      >
        <Row>
          <Col
            xl={5}
            md={6}
            className="d-flex justify-content-center align-items-center p-0"
          >
            <Image src={imageBanner} alt="bannerMobile" fluid />
          </Col>
          <Col xl={4} md={5}>
            <div className="textBanner">
              {t("buttonsAndAccesories.downloadText", {
                downloadText: `${textBanner} `,
                interpolation: { escapeValue: false },
              })}
            </div>
            <div className="buttonFreeDownload">
              <Button
                variant="primary"
                className="btn-download"
                id="buttonDownload"
                href={brochureUrl ? brochureUrl : downloadUrl}
                target="_blank"
              >
                {actualLang === "en"
                  ? "Download company overview"
                  : "Descargar porfolio de soluciones"}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="d-block d-sm-block d-md-none containerBannerDownloadMobile">
        <div className="imagemobileUsa">
          <Image src={imageBanner} alt="bannerMobile" />
        </div>
        <Row>
          <Col
            md={{ span: 6, offset: 6 }}
            lg={{ span: 5, offset: 7 }}
            xl={{ span: 4, offset: 7 }}
            xxl={{ span: 3, offset: 7 }}
            className="mt-5"
          >
            <div className="marginContent">
              <div className="textBannerMobile text-center">
                {t("buttonsAndAccesories.downloadText", {
                  downloadText: `${textBanner} `,
                  interpolation: { escapeValue: false },
                })}
              </div>
              <Row className="m-4">
                <Col className="justify-content-center align-items-center d-flex m-4">
                  <Button
                    variant="secondary"
                    className="btn-download"
                    href={brochureUrl ? brochureUrl : downloadUrl}
                    target="_blank"
                  >
                    {actualLang === "en"
                      ? "Download company overview"
                      : "Descargar porfolio de soluciones"}
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
