import React, { useContext, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import isopk1 from "../../assets/iso1pk.svg";
import isopk2 from "../../assets/iso2pk.svg";
import "../../css/service.css";
import { menuServices } from "../../config/home/textPage";
import { useTranslation } from "react-i18next";
import chevronRight from "../../assets/icons/chevron-right.png";
import { useSolutionsContext } from "./Solutions/context/ContentSolutionsContext";
import { ActualLangContext } from "../../contexts/ActualLangContext";

const ServicesCard = ({ subtitle, text1, text2, id }) => {
  const { t } = useTranslation();
  const [imgIso1, setImgIso1] = useState(isopk1);
  const {
    solutions,
    setSelectedArea,
    SOLUTIONS_AREAS,
    selectedArea,
    setSelectedSolution,
  } = useSolutionsContext();

  const { actualLang } = useContext(ActualLangContext);

  return (
    <Col md={6}>
      <Button
        variant="seeMore"
        className="card"
        href={actualLang === "es" ? "/soluciones" : "/solutions"}
        onMouseLeave={() => setImgIso1(isopk1)}
        onMouseEnter={() => setImgIso1(isopk2)}
        onClick={() => {
          const solution = SOLUTIONS_AREAS[id];
          if (selectedArea !== solution) {
            setSelectedSolution(
              solutions.filter((sol) => sol.area === solution)[0].solutions[0]
                .topTitle
            );
          }
          setSelectedArea(solution);
        }}
      >
        <h5>{subtitle}</h5>
        <div className="d-flex align-items-center">
          <Image
            src={imgIso1}
            className="img-fluid"
            alt="Software Engineering"
          />
          <div className="left">
            <h4>
              {text1}
              {text2 ? (
                <>
                  <br />
                  {text2}
                </>
              ) : (
                ""
              )}
            </h4>
          </div>
        </div>
        <div className="left">
          <p>
            {t("menuServices.button", {
              button: `${menuServices.button}`,
            })}
            <Image src={chevronRight} className="chevron" alt="chevron-right" />
          </p>
        </div>
      </Button>
    </Col>
  );
};

const ServicesUsa = () => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 0,
      subtitle: t("menuServices.digitalProductSubtitle", {
        digitalProductSubtitle: `${menuServices.digitalProductSubtitle}`,
      }),
      text1: t("menuServices.digitalProductPart1", {
        digitalProductPart1: `${menuServices.digitalProductPart1}`,
      }),
      text2: t("menuServices.digitalProductPart2", {
        digitalProductPart2: `${menuServices.digitalProductPart2}`,
      }),
    },
    {
      id: 1,
      subtitle: t("menuServices.cloudAndDevOpsSubtitle", {
        cloudAndDevOpsSubtitle: `${menuServices.cloudAndDevOpsSubtitle}`,
      }),
      text1: t("menuServices.cloudAndDevOpsPart1", {
        cloudAndDevOpsPart1: `${menuServices.cloudAndDevOpsPart1}`,
      }),
      text2: t("menuServices.cloudAndDevOpsPart2", {
        cloudAndDevOpsPart2: `${menuServices.cloudAndDevOpsPart2}`,
      }),
    },
    {
      id: 2,
      subtitle: t("menuServices.dataScienceSubtitle", {
        dataScienceSubtitle: `${menuServices.dataScienceSubtitle}`,
      }),
      text1: t("menuServices.dataSciencePart1", {
        dataSciencePart1: `${menuServices.dataSciencePart1}`,
      }),
      text2: t("menuServices.dataSciencePart2", {
        dataSciencePart2: `${menuServices.dataSciencePart2}`,
      }),
    },
    {
      id: 3,
      subtitle: t("menuServices.aiEngineeringSubtitle", {
        aiEngineeringSubtitle: `${menuServices.aiEngineeringSubtitle}`,
      }),
      text1: t("menuServices.aiEngineeringPart1", {
        aiEngineeringPart1: `${menuServices.aiEngineeringPart1}`,
      }),
      text2: t("menuServices.aiEngineeringPart2", {
        aiEngineeringPart2: `${menuServices.aiEngineeringPart2}`,
      }),
    },
  ];

  return (
    <Container fluid id="whatMovesCirculo">
      <Container id="services">
        <Row className="justify-content-center">
          <Col>
            <p className="sup-title text-uppercase text-center">
              {t("menuServices.title", {
                title: `${menuServices.title}`,
              })}
            </p>
          </Col>
        </Row>

        <Col md={{ span: 10, offset: 1 }}>
          <Row className="listServices">
            {cards.map((card) => (
              <ServicesCard {...card} />
            ))}
          </Row>
        </Col>
      </Container>
    </Container>
  );
};

export default ServicesUsa;
