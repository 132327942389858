export const bannerText = {
  title: "Scale your software development",
  subTitle:
    "Deliver delightful experiences to your customers with our software engineering solutions",
  buttonConsult: "Get Free Consultation",
  ourServices: "Our solutions",
};

export const engagementText = {
  topTitle: "Solutions",
  title: "Work model",
  teamCapacity: {
    title: "Team capacity / Talent on demand",
    microsoftArchitect: "Technical/Cloud Architect",
    developmentRoles:
      "Sample Developer Roles: Java / .NET / Python / Front End (React, Angular)",
    powerApps: "PowerApps",
    uxUi: "UX/UI",
    automationQA: "QA Automation Eng",
    devOps: "DevOps Eng",
    scientificRoles: "Data/AI Scientist/Eng",
    productOwner: "Business/Analyst/Product Management",
    agileCoach: "Agile coach/Scrum master/Project management",
  },
  agileTeams: {
    title: "Agile teams",
    nearshore: "**Nearshore.** Remote LATAM",
    hybrid: "**Hybrid.** Onshore Nearshore",
    purePossumus: "**Pure Possumus.** Combined Client Composition",
    productDevelopment: "Software development focused digital products",
  },
  projectBasedWork: {
    title: "Project based work",
    roadmap: "Deliverables",
    deliverables: "Milestones",
  },
  managedServices: {
    title: "Managed services",
    support: "Ongoing Support & Maintenance",
    processes: "Well-defined processes",
    models: "Usually SLA-based",
    contracts: "Mid/long term contracts",
  },
};
