export const bannerText = {
  title:
    "We connect ideas with innovation to transform businesses into exceptional experiences",
  subTitle:
    "We specialize in delivering powerful, custom software engineering solutions",
  buttonConsult: "Get Free Consultation",
  ourServices: "Our solutions",
  certificatePart1: "Our processes are accredited by ISO 9001:2015",
  certificatePart2:
    "certification in quality practices for the development of software products.",
};

export const trusted = {
  whoTrustUs: "Who trust us",
};
export const menuServices = {
  title: "Solutions",
  subtitle1: "Get your business up and running with",
  subtitle2: "our solutions",
  softPart1: "Software",
  softPart2: "Engineering",
  button: "Learn More",
  sre: "Cloud Engineering & DevOps Practice",
  staffPart1: "Staff",
  staffPart2: "Augmentation",
  staffPart1MenuHeader: "Staff augmentation",
  digitalProductSubtitle:
    "Drive your digital vision with tailored solutions that transform business",
  digitalProductPart1: "Digital Product ",
  digitalProductPart2: "Engineering",
  cloudAndDevOpsSubtitle:
    "Optimize your infrastructure with secure cloud solutions and reliable IT management",
  cloudAndDevOpsPart1: "Managed Cloud ",
  cloudAndDevOpsPart2: "and DevOps Services",
  dataScienceSubtitle:
    "Turn data into strategic decisions with our advanced analytics",
  dataSciencePart1: "Data ",
  dataSciencePart2: "Science",
  aiEngineeringSubtitle:
    "Leverage your business with AI strategies and custom machine learning solutions",
  aiEngineeringPart1: "AI/ML Engineering ",
  aiEngineeringPart2: "& AI Strategy",
  buttonSend: "Send now",
};
export const aboutUsWhatMovesUs = {
  aboutMenuHeader: "About",
  titleLinkMenu: "About",
  title: "About us",
  subTitle: "What moves us",
  description1: `We help companies transform their ideas into effective software products and design, operate, and scale their digital ecosystems by using best practices in software development, platform engineering, and digital transformation, ensuring they innovate, remain competitive, and stay relevant in the market.`,
  whatWeAre: "What we are",
};
export const valueProposal = {
  title: "Value proposal",
  description1: `We provide services that address this critical gap, enabling non-tech-savvy companies to confidently navigate the complexity of the technological and digital landscape, innovate without limitations, and remain competitive, ensuring relevance and success in a market that does not forgive those who fail to adapt.`,
  description2: `We co-create high-quality software products with our clients, addressing digital transformation and always emphasizing design and usability.`,
  button: "More about us",
};

export const organizationalPolicy = {
  title: "Organizational policy",
};
export const careers = {
  title: "Careers",
  work: "Work with us!",
  apply: "Apply now",
};
export const contact = {
  title: "Contact",
  contactLink: "Contact us",
};
export const downloadFree = {
  title: "Download our deck and let’s talk",
};
export const page404 = {
  title: "Something gone missing...",
  paragrahp:
    "Sorry, the content you are looking for does not exist. Either it has been removed, or you mistyped the link.",
  button: "Go Home",
};
