export const solutionsEs = {
  banner: {
    title: "Escala tus proyectos de software sin limites",
    subtitle:
      "Crea experiencias increíbles para tus clientes con nuestras soluciones de ingeniería de software.",
    buttonConsult: "Consulta gratis",
    ourServices: "Nuestras soluciones",
  },
  engagement: {
    topTitle: "SOLUCIONES",
    title: "Modelo de trabajo",
    teamCapacity: {
      title: "Team capacity / Talent on demand",
      microsoftArchitect: "Arquitecto técnico/Cloud",
      developmentRoles:
        "Roles de desarrollo de software: Java /.NET/ Python, Frontend (React, Angular)",
      powerApps: "PowerApps",
      uxUi: "UX/UI",
      automationQA: "Ingeniería en automatización de QA",
      devOps: "Ingeniería DevOps",
      scientificRoles: "Data / Científico de datos / Ingeniero",
      productOwner: "Business analyst/Product management",
      agileCoach: "Agile coach/Scrum master/Project management",
    },
    agileTeams: {
      title: "Equipos ágiles co-gestionados",
      nearshore: "**Nearshore.** Remoto desde LATAM",
      hybrid: "**Híbrido.** Combinación de onshore y nearshore",
      purePossumus: "**Modelo Pure Possimus** Modelo con integración del cliente",
      productDevelopment:
        "Desarrollo de software enfocado en productos digitales",
    },
    projectBasedWork: {
      title: "Project based work",
      roadmap: "Roadmap con hitos definidos",
      deliverables: "Desarrollo basado en entregables",
    },
    managedServices: {
      title: "Servicios gestionados",
      support: "Soporte y mantenimiento evolutivo",
      processes: "Procesos estandarizados y eficientes",
      models: "Modelos de servicio SLA",
      contracts: "Contratos a mediano y largo plazo",
    },
  },
};

export const solutionsEn = {
  banner: {
    title: "{{title}}",
    subtitle: "{{subtitle}}",
    buttonConsult: "{{buttonConsult}}",
    ourServices: "{{ourServices}}",
  },
  engagement: {
    topTitle: "{{topTitle}}",
    title: "{{title}}",
    teamCapacity: {
      title: "{{title}}",
      microsoftArchitect: "{{microsoftArchitect}}",
      developmentRoles: "{{developmentRoles}}",
      powerApps: "{{powerApps}}",
      uxUi: "{{uxUi}}",
      automationQA: "{{automationQA}}",
      devOps: "{{devOps}}",
      scientificRoles: "{{scientificRoles}}",
      productOwner: "{{productOwner}}",
      agileCoach: "{{agileCoach}}",
    },
    agileTeams: {
      title: "{{title}}",
      nearshore: "{{nearshore}}",
      hybrid: "{{hybrid}}",
      purePossumus: "{{purePossumus}}",
      productDevelopment: "{{productDevelopment}}",
    },
    projectBasedWork: {
      title: "{{title}}",
      roadmap: "{{roadmap}}",
      deliverables: "{{deliverables}}",
    },
    managedServices: {
      title: "{{title}}",
      support: "{{support}}",
      processes: "{{processes}}",
      models: "{{models}}",
      contracts: "{{contracts}}",
    },
  },
};
