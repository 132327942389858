import { createContext, useState, useEffect } from "react";

export const ActualLangContext = createContext({});

export const ActualLangProvider = ({ children }) => {
  const getInitialLang = () => {
    const storedLang = localStorage.getItem("lang");
    if (storedLang) return storedLang;
    return navigator.language.includes("es") ? "es" : "en";
  };

  const [actualLang, setActualLang] = useState(getInitialLang);

  useEffect(() => {
    localStorage.setItem("lang", actualLang);
    localStorage.setItem("i18nextLng", actualLang);
  }, [actualLang]);

  const setLang = (newLang) => {
    setActualLang(newLang);
  };

  return (
    <ActualLangContext.Provider value={{ actualLang, setLang }}>
      {children}
    </ActualLangContext.Provider>
  );
};
