import { DownloadBanner } from "../components/commons/downloadBanner";
import MenuUsa from "../components/commons/menuUsa";
import { Solutions } from "../components/home/Solutions";
import { Banner, Engagement } from "../components/Solutions";
import { Separator } from "../components/VerticalGenericPage";
import sebastian from "../assets/landings/houston/team/SebastianDelfino.svg";
import federico from "../assets/landings/houston/team/FedericoTagliani.png";
import fernando from "../assets/landings/houston/team/FernandoBulczynski.png";
import marisa from "../assets/landings/houston/team/MarisaVaira.svg";
import GetInTouchMultiple from "../components/commons/getInTouchMultiple";
import { LocationAddress } from "../components/usa/locationAddress";
import { Footer } from "../components/commons/footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useContext } from "react";
import { ActualLangContext } from "../contexts/ActualLangContext";

export const SolutionsPage = () => {
  const { actualLang } = useContext(ActualLangContext);

  const isSpanish = actualLang === "es";

  const personalES = [
    {
      nombre_apellido: "Fernando Bulczynski",
      puesto: "Chief Growth Officer",
      Image_personal: { url: fernando },
    },
    {
      nombre_apellido: "Marisa Vaira",
      puesto: "Sales Consultant",
      Image_personal: { url: marisa },
    },
  ];
  const personalEN = [
    {
      nombre_apellido: "Federico Tagliani",
      puesto: "Managing Director",
      Image_personal: {
        url: federico,
      },
    },
    {
      nombre_apellido: "Sebastian Delfino",
      puesto: "Business Developer",
      Image_personal: {
        url: sebastian,
      },
    },
  ];
  const personal = isSpanish ? personalES : personalEN;
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Possumus | {isSpanish ? "Soluciones" : "Solutions"}</title>
          <meta
            property="og:title"
            content={`Possumus | ${isSpanish ? "Soluciones" : "Solutions"}`}
          />
        </Helmet>
      </HelmetProvider>
      <MenuUsa />
      <Banner />
      <Solutions />
      <Engagement />
      <Separator
        title={
          isSpanish
            ? "¿Te animas a potenciar tu empresa?"
            : "Ready to elevate your business?"
        }
        description={
          isSpanish
            ? "Contáctanos y te contamos cómo diseñar soluciones empresariales que crecen contigo. Combinamos tecnología low code y experiencia técnica para transformar tus operaciones en ventaja competitiva."
            : "Contact us today to learn how enterprise solutions tailored to your business can create a competitive advantage. We combine low-code technology with technical expertise to help you achieve your goals."
        }
        slogan={
          isSpanish
            ? "Empodera tu empresa con soluciones que crecen contigo y transforman tu ventaja competitiva."
            : "Empower your business with enterprise solutions that evolve with your needs and transform operations into measurable results."
        }
      />
      <DownloadBanner />
      <GetInTouchMultiple personal={personal} />
      <LocationAddress />
      <Footer />
    </>
  );
};
